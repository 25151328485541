<template>
      <div align="center" class="camera-modal">
        <video   
          ref="video" class="camera-stream"/>
        <div class="camera-modal-container">
        <!-- <img :src="imgcapture.type" /> -->
        <v-img
          max-height="150"
          max-width="250"
          :src="imgcapture.type"
        ></v-img>
            <v-btn
            class="take-picture-button take-picture-button mdl-button mdl-js-button mdl-button--fab mdl-button--colored"
            @click="capture()" 
                color="warning"
                fab
                small
                dark
              >
              <v-icon>mdi-camera</v-icon>
            </v-btn>
        </div>
    </div>

</template>
<script>
// import NotificationPopup from "@/components/NotificationPopup.vue";
// import { WebCam } from "vue-web-cam";

  export default {
    name: 'KYC',
    components: { 
    // NotificationPopup,
    // WebCam,
    },
    data() {
      return {
        mediaStream: null,
        imgcapture: ""
      }
    },
    methods:{
      capture () {
        //  const mediaStreamTrack = this.mediaStream.getVideoTracks()[0]
        //  const imageCapture = new window.ImageCapture(mediaStreamTrack)

          // return imageCapture.takePhoto().then(blob => {
          //   storage.ref().child('images/picture' + new Date().getTime()).put(blob)
          //     .then(res => {
          //       this.postCat(res.metadata.downloadURLs[0])
          //     })
          // })
        // console.log('capture ', this.mediaStream)
        const mediaStreamTrack = this.mediaStream.getVideoTracks()[0]
        // console.log('capture ', this.mediaStream)
        // ImageCapture.getPhotoCapabilities()
        const imageCapture = new window.ImageCapture(mediaStreamTrack)
        return imageCapture.takePhoto().then(blob => {
            console.log(blob)
            this.imgcapture =blob
            
            // this.imgcapture = 'data:image/png;base64,' + btoa(
            //   new Uint8Array(this.blob.image)
            //   .reduce((data, byte) => data + String.fromCharCode(byte), '')
            // );
        })
      },
    },
    mounted () {
       navigator.mediaDevices.getUserMedia({ video: true })
        .then(mediaStream => {
          console.log('mediaStream ', mediaStream)
          this.mediaStream = mediaStream
          this.$refs.video.srcObject = mediaStream
          this.$refs.video.play()
        })
        .catch(error => console.error('getUserMedia() error:', error))
    },
    destroyed () {
      const tracks = this.mediaStream.getTracks()
      tracks.map(track => track.stop())
    },

  }
</script>

<style lang="scss" scoped>
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.h4-title{
  padding-top: 10px;
  padding-left: 10px;
}

  .camera-modal {
      width: 100%;
      // width: 100vh;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: white;
      z-index: 10;
  }
  .camera-stream {
      width: 100%;
      // width: 100vh;
      max-height: 100%;
  }

  .camera-modal-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
    }
    .take-picture-button {
        display: flex;
    }
</style>